import axios, { AxiosInstance } from "axios";
import { IRestResponse, IDataResponse } from "../interfaces/rest";
import BBPromise from "bluebird";
import AuthUtils from "../utils/authUtils";
import { Auth } from "aws-amplify";
import { logout } from "../store/reducers/authSlice";
import store from "../store";
// import _ from "lodash";

BBPromise.config({ cancellation: true });

export default class Api {
  axiosClient: any = null;
  configClient(additioalHeaders: any = null) {
    // let headers = {};
    // if (additioalHeaders) {
    //   _.merge(headers, additioalHeaders);
    // }

    const client: AxiosInstance = axios.create({
      baseURL: this.baseUrl(),
      timeout: 30000,
        // headers,
    });
    return client;
  }

  getClient(
    callback: (client: AxiosInstance) => void,
    isPrivate: boolean = false
  ) {
    try {
      if(!isPrivate) { // public api, so no need to check for token
        callback(this.configClient());
      } else {
        if (AuthUtils.getIdToken() && !AuthUtils.isTokenExpired()) {
          callback(this.configClient());
        } else {
          if(AuthUtils.getRememberMe()) {
            Auth.currentSession()
              .then((response: any) => {
                if (response.accessToken) {
                  localStorage.setItem(
                    "access_token",
                    response.accessToken.jwtToken
                  );
                  localStorage.setItem("id_token", response.idToken.jwtToken);
                  callback(this.configClient());
                } else {
                  throw new Error("No access token");
                }
              })
              .catch((error: any) => {
                console.log(error);
                AuthUtils.performLogout();
                store.dispatch(logout());
              });
          } else {
            AuthUtils.performLogout();
            store.dispatch(logout());
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  baseUrl() {
    const location = window.location;
    const baseurl = location.protocol + "//" + location.host;

    return baseurl;
  }

  getResponseData(response: IRestResponse): IDataResponse | null {
    if (!response) {
      return null;
    }
    const data = response.data;
    return data;
  }

  getResponsePayload(response: IRestResponse): any {
    if (response && this.getResponseData(response)) {
      return this.getResponseData(response)!.payload;
    }
    return null;
  }

  getResponseMessage(response: IRestResponse): any {
    if (response && this.getResponseData(response)) {
      return this.getResponseData(response)!.message;
    }
    return null;
  }

  getDataStatus(response: IRestResponse): any {
    if (response && this.getResponseData(response)) {
      return this.getResponseData(response)!.status;
    }
    return null;
  }

  generateFakeResponse(
    payload: any,
    status: "success" | "error",
    reststatus = 200
  ): IRestResponse {
    const response: IRestResponse = {
      config: null,
      headers: null,
      request: null,
      status: reststatus,
      statusText: "",
      data: {
        status,
        error: "",
        message: "",
        payload,
      },
    };
    return response;
  }
}
