import {
  Button,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { validateNewPassword } from "../../utils/passwordUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type ResetCodeFormProps = {
  onSubmit: (resetCode: string, newPassword: string) => void;
  onBack: () => void;
  resetCodeError: string;
};

function ResetCodeForm(props: ResetCodeFormProps) {
  const [resetCode, setResetCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<string[]>(
    []
  );
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.onSubmit(resetCode, newPassword);
  };

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
    const errors = validateNewPassword(e.target.value, confirmPassword);
    setConfirmPasswordErrors(errors);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    const errors = validateNewPassword(e.target.value, newPassword);
    setConfirmPasswordErrors(errors);
  };

  useEffect(() => {
    if (
      !resetCode ||
      !newPassword ||
      (newPassword && newPassword !== confirmPassword)
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [resetCode, newPassword, confirmPassword]);

  return (
    <div className="cw-login-form">
      <div className="cw-content">
        <h1>Insert your reset code</h1>
        <p style={{ margin: 0, fontSize: "0.95rem", textAlign: "center" }}>
          We have sent you a reset code at your email address.
        </p>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-reset-code-input">
            Reset code <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-reset-code-input"
            placeholder="Reset code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={resetCode}
            onChange={(e) => setResetCode(e.target.value)}
            error={props.resetCodeError !== ""}
            helperText={props.resetCodeError}
          />
        </div>
        <h1>Provide the new password</h1>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-new-password-input">
            New Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-new-password-input"
            placeholder="New Password"
            variant="outlined"
            minRows={5}
            style={{ marginRight: "0.7vw" }}
            value={newPassword}
            onChange={handleNewPasswordChange}
            type={showNewPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-confirm-password-input">
            Confirm Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-confirm-password-input"
            placeholder="Confirm Password"
            variant="outlined"
            minRows={5}
            style={{ marginRight: "0.7vw" }}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
            error={confirmPasswordErrors.length > 0}
            helperText={
              confirmPasswordErrors.length > 0 && (
                <List dense>
                  {confirmPasswordErrors.map((error) => (
                    <ListItem key={error}>
                      <ListItemText primary={error} />
                    </ListItem>
                  ))}
                </List>
              )
            }
          />
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isButtonDisabled}
            onClick={handleSubmit}
          >
            Confirm change password
          </Button>
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={props.onBack}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResetCodeForm;
