export const validateNewPassword = (password: string, confirmPassword: string) => {
    const errors: string[] = [];
    if (password.length > 0) {
        if (password.length < 8) {
          errors.push("Password must be at least 8 characters long");
        }
        //check if there is at least one number
        if (!/\d/.test(password)) {
          errors.push("Password must contain at least one number");
        }
        //check if there is at least one lowercase letter
        if (!/[a-z]/.test(password)) {
          errors.push("Password must contain at least one lowercase letter");
        }
        //check if there is at least one uppercase letter
        if (!/[A-Z]/.test(password)) {
          errors.push("Password must contain at least one uppercase letter");
        }
        //check if there is at least one special character
        if (!/[!?@#$%^&*:_.,;<>()|]/.test(password)) {
          errors.push("Password must contain at least one special character");
        }
        if (password.includes(" ")) {
          errors.push("Password cannot contain spaces");
        }
        if (password !== confirmPassword) {
          errors.push("The new password and the confirm password do not match");
        }
      }
    return errors;
}