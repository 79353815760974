import { Button, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useState } from "react";

type ForgotPasswordFormProps = {
  onSubmit: (username: string) => void;
  onBack: () => void;
  forgotPasswordError: string;
};

function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const [username, setUsername] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.onSubmit(username);
  };

  return (
    <div className="cw-login-form">
      <div className="cw-content">
        <h1>Forgot Password</h1>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-username-input">
            Email Address <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-username-input"
            placeholder="Email Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={props.forgotPasswordError ? true : false}
            helperText={props.forgotPasswordError}
          />
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!username}
            onClick={handleSubmit}
          >
            Request change password
          </Button>
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={props.onBack}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordForm;
