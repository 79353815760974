import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import UsersApi from "../../api/usersApi";
import DigestsApi from "../../api/digestsApi";
import { IUser } from "../../interfaces/user";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import _ from "lodash";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AuthUtils from "../../utils/authUtils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 200,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const emptyUser: IUser= {
  blacklisted: "",
  user_name: "",
  email: "",
}

export default function UsersTable(props: any) {
  const { filter, trigger, setUsersParent } = props;
  const [users, setUsers] = useState<IUser[]>([]);
  const [currentUser, setCurrentUser] = useState(emptyUser)
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (user : IUser) => {
    setOpenModal(true);
    setCurrentUser(user)
  }
  const handleClose = () => {
    setOpenModal(false);
    setCurrentUser(emptyUser);
  }
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [orderBy, setOrderBy] = useState({
    id: "user_name",
    type: "asc",
  });

  useEffect(() => {
    setOpenBackDrop(true);
    UsersApi.getUsersList()
      .then((response: any) => {
        response.data.forEach((user: IUser) => {
          // user.signature = Math.random();//to do  
        });
        for(var i = 0 ; i < response.data.length; i++){
          if (response.data[i].user_role === "TenantAdmin"){
              response.data.splice(i, 1);
              break;
          }
        }
        setUsers(response.data);
        setUsersParent(response.data);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setOpenBackDrop(false);
      });
  }, [trigger]);

  useEffect(() => {
    let orderedUsers;
    orderedUsers = _.orderBy(
      users,
      [orderBy.id],
      [(orderBy.type as "asc") || "desc"]
    );
    setUsers(orderedUsers);
  }, [orderBy]);

  const handleSortBy = (id: string) => {
    const newOrderBy = {
      id: id,
      type: orderBy.id !== id ? "asc" : orderBy.type === "asc" ? "desc" : "asc",
    };
    setOrderBy(newOrderBy);
  };

  const handleBlacklist = (userBlacklisted: IUser) => {

    const attributes = [{
      'Name' :'custom:blacklisted',
      'Value': userBlacklisted["blacklisted"] === "false" ? "true" : "false"
      },
    ];
    setOpenBackDrop(true);
    UsersApi.updateUser(userBlacklisted["user_name"], attributes)
        .then((response: any) => {
          let newUsers  : any = _.cloneDeep(users);
          let index = users.indexOf(userBlacklisted);
          newUsers[index]["blacklisted"] = newUsers[index]["blacklisted"] === "false" ? "true" :"false";
          setUsers(newUsers);

         
          let value=userBlacklisted["blacklisted"] === "false" ? true : false;
          DigestsApi.updateBlacklistedValueOfDigests(userBlacklisted["user_name"],value)
            .catch((error: any) => {
              console.log(error);
            })
        })
        .catch((error: any) => {
          console.log(error);
        })
        .finally(() => {
          setOpenBackDrop(false);
          handleClose();
        });
    
  

  };


  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal 
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-add-user-title"
        aria-describedby="modal-add-user-description"
        >
        <Box sx={style} style={{textAlign:"center"}}>
          <Typography id="modal-add-user-title" variant="h6" component="h2" >
            { currentUser["blacklisted"]  === "true" ? `Are you sure you want to remove "${currentUser["user_name"]} from the blacklist ?` : `Are you sure you want to add "${currentUser["user_name"]}" to the blacklist ?`}
          </Typography>
          <div id="modal-add-user-description">
            <Grid
              container
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="top"
              style={{ height: "100%" }}
            >
              <Grid item xs={3} style={{ width: "100%" , marginTop:"10%"}}>
                <Button style={{marginRight:"5%"}}variant="contained" color="error" onClick={handleClose}>
                  No
                </Button>
                <Button style={{marginLeft:"5%"}}variant="contained" color="success" onClick={() => handleBlacklist (currentUser)}>
                  Yes
                </Button>
              </Grid>
            </Grid>
          </div>
        </Box>
      </Modal>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ width: "30%" }}
                className={
                  "user-table-head allow-order " +
                  (orderBy.id === "user_name" ? "selected" : "")
                }
                onClick={() => handleSortBy("user_name")}
              >
                User Name
                {orderBy["id"] === "user_name" ? (
                  orderBy["type"] === "desc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                ) : (
                  <ArrowDropDownIcon />
                )}
              </TableCell>
              <TableCell
                className={
                  "user-table-head allow-order " +
                  (orderBy.id === "user_role" ? "selected" : "")
                }
                onClick={() => handleSortBy("user_role")}
              >
                User Role
                {orderBy["id"] === "user_role" ? (
                  orderBy["type"] === "desc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                ) : (
                  <ArrowDropDownIcon />
                )}
              </TableCell>
              <TableCell
                className={
                  "user-table-head allow-order " +
                  (orderBy.id === "signature" ? "selected" : "")
                }
                onClick={() => handleSortBy("signature")}
              >
                Signature
                {orderBy["id"] === "signature" ? (
                  orderBy["type"] === "desc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                ) : (
                  <ArrowDropDownIcon />
                )}
              </TableCell>
              <TableCell className="user-table-head">e-mail</TableCell>
              <TableCell className="user-table-head">2FA</TableCell>
              <TableCell className="user-table-head">Blacklisted</TableCell>
              <TableCell className="user-table-head"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users
              .filter(
                (user: IUser) =>
                  !filter ||
                  filter.length === 0 ||
                  user.user_name.startsWith(filter)
              )
              .map((row: IUser) => (
                <TableRow
                  key={row.user_name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.user_name}
                  </TableCell>
                  <TableCell>{row.user_role || ""}</TableCell>
                  <TableCell>{row.signature || ""}</TableCell>
                  <TableCell>{row.email || ""}</TableCell>
                  <TableCell>{row.tfa}</TableCell>
                  <TableCell>{row.blacklisted}</TableCell>
                  <TableCell>
                    <Button 
                      onClick={() => handleOpen(row)}>{row.blacklisted === "false" ? "Add" : "Remove"}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
