import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { validateNewPassword } from "../../utils/passwordUtils";

interface ResetPasswordFormProps {
  onSubmit: (newPassword: string) => void;
  onBack: () => void;
  oldPassword: string;
  resetPasswordError: string;
}

function ResetPasswordForm(props: ResetPasswordFormProps) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<string[]>(
    []
  );
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleSubmit = (e: any) => {
    props.onSubmit(newPassword);
  };

  const handleNewPasswordChange = (e: any) => {
    setNewPassword(e.target.value);
    const errors = validateNewPassword(e.target.value, confirmPassword);
    setConfirmPasswordErrors(errors);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPassword(e.target.value);
    const errors = validateNewPassword(e.target.value, newPassword);
    setConfirmPasswordErrors(errors);
  };

  useEffect(() => {
    if (
      !oldPassword ||
      (oldPassword && oldPassword !== props.oldPassword) ||
      !newPassword ||
      (newPassword && newPassword !== confirmPassword) ||
      confirmPasswordErrors.length > 0
    ) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [oldPassword, newPassword, confirmPassword, props.oldPassword]);

  return (
    <div className="cw-login-form">
      <div className="cw-content">
        <h1>Provide the new password</h1>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-old-password-input">
            Old Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-old-password-input"
            placeholder="Old Password"
            variant="outlined"
            minRows={5}
            style={{ marginRight: "0.7vw" }}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            type={showOldPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputAdornment>
              ),
            }}
            error={oldPassword.length > 0 && oldPassword !== props.oldPassword}
            helperText={
              oldPassword.length > 0 && oldPassword !== props.oldPassword
                ? "Old password is incorrect"
                : ""
            }
          />
        </div>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-new-password-input">
            New Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-new-password-input"
            placeholder="New Password"
            variant="outlined"
            minRows={5}
            style={{ marginRight: "0.7vw" }}
            value={newPassword}
            onChange={handleNewPasswordChange}
            type={showNewPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-confirm-password-input">
            Confirm Password <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-confirm-password-input"
            placeholder="Confirm Password"
            variant="outlined"
            minRows={5}
            style={{ marginRight: "0.7vw" }}
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            type={showConfirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <VisibilityIcon />
                  ) : (
                    <VisibilityOffIcon />
                  )}
                </InputAdornment>
              ),
            }}
            error={confirmPasswordErrors.length > 0}
            helperText={
              confirmPasswordErrors.length > 0 && (
                <List dense>
                  {confirmPasswordErrors.map((error) => (
                    <ListItem key={error}>
                      <ListItemText primary={error} />
                    </ListItem>
                  ))}
                </List>
              )
            }
          />
        </div>
        <div
          className="cw-input-box"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {props.resetPasswordError ? (
            <p style={{ margin: 0, fontSize: "0.95rem", textAlign: "center" }}>
              {props.resetPasswordError}
            </p>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={isButtonDisabled}
              onClick={handleSubmit}
            >
              Change Password
            </Button>
          )}
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={props.onBack}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordForm;
