import { Button, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useState } from "react";

type SoftwareTokenMFAFormProps = {
  onSubmit: (code: string) => void;
  onBack: () => void;
  MFAError: string;
};

function SoftwareTokenMFAForm(props: SoftwareTokenMFAFormProps) {
  const [code, setCode] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.onSubmit(code);
  };

  return (
    <div className="cw-login-form">
      <div className="cw-content">
        <h1>Enter OTP Code</h1>
        <div className="cw-input-box">
          <InputLabel htmlFor="cw-code-input">
            Code <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <TextField
            className="cw-code-input"
            placeholder="Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            error={props.MFAError ? true : false}
            helperText={props.MFAError}
          />
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!code}
            onClick={handleSubmit}
          >
            Submit OTP code
          </Button>
        </div>
        <div className="cw-input-box">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={props.onBack}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SoftwareTokenMFAForm;