import { Auth } from "aws-amplify";
import _, { set } from "lodash";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import TenantsApi from "./api/tenantsApi";
import LateralMenu from "./components/menus/LateralMenu";
import Topbar from "./components/toolbars/Topbar";
import "./css/App.css";
import { environment } from "./environments/environment";
import "./fonts/OPTIOpus.otf";
import { getRoutesGroupByName } from "./routes";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { init } from "./store/reducers/authSlice";
import AuthUtils from "./utils/authUtils";

const forbiddenTenants = ["none", "staging", "dev", "test", "production"];

function App(props: any) {
  const dispatch = useAppDispatch();
  const [routesType, setRoutesType] = useState("");
  const accessibleRoutes: any[] = getRoutesGroupByName(routesType);
  const [userWasLogged, setUserWasLogged] = useState(false);
  const [tenantName] = useState(() => {
    if (window.location.host.includes("localhost"))
      return environment.tenantName;
    else
      return forbiddenTenants.includes(window.location.host.split(".")[0])
        ? "none"
        : window.location.host.split(".")[0];
  });

  useEffect(() => {
    if (tenantName !== "none") {
      TenantsApi.getTenantConfig(tenantName)
        .then((response: any) => {
          localStorage.setItem(
            "userPoolId",
            response.data.userPoolId.replaceAll('"', "")
          );
          localStorage.setItem(
            "appClientId",
            response.data.appClientId.replaceAll('"', "")
          );
          localStorage.setItem(
            "apiGatewayUrl",
            response.data.apiGatewayUrl.replaceAll('"', "")
          );
          localStorage.setItem(
            "tenantTier",
            response.data.tenantTier.replaceAll('"', "")
          );
          localStorage.setItem("tenantName", tenantName);
          Auth.configure({
            userPoolId: response.data.userPoolId,
            userPoolWebClientId: response.data.appClientId,
            region: response.data.userPoolId.split("_")[0],
            // storage: AuthUtils.getRememberMe() ? localStorage : sessionStorage,
            authenticationFlowType: "CUSTOM_AUTH", //'USER_SRP_AUTH' | 'USER_PASSWORD_AUTH' | 'CUSTOM_AUTH' | 'ADMIN_NO_SRP_AUTH'
          });

          // now we can also re-login the user silently if they have logged in with remember me
          if (AuthUtils.getRememberMe()) {
            Auth.currentSession()
              .then((response: any) => {
                if (response.accessToken) {
                  localStorage.setItem(
                    "access_token",
                    response.accessToken.jwtToken
                  );
                  localStorage.setItem("id_token", response.idToken.jwtToken);
                  initializePrivateRoutes();
                } else {
                  AuthUtils.performLogout();
                  setRoutesType("public");
                }
              })
              .catch((error: any) => {
                console.log(error);
                AuthUtils.performLogout();
                setRoutesType("public");
              });
          } else {
            if (AuthUtils.getIdToken() && !AuthUtils.isTokenExpired()) {
              initializePrivateRoutes();
            } else {
              AuthUtils.performLogout();
              setRoutesType("public");
            }
          }
        })
        .catch((error: any) => {
          console.log(error);
          setRoutesType("public");
        });
    } else {
      setRoutesType("public");
    }
  }, [tenantName]);

  async function initAuthState() {
    const payload = {
      logged: true,
      access_token: AuthUtils.getAccessToken(),
      id_token: AuthUtils.getIdToken(),
    };
    dispatch(init(payload));
  }

  async function initializePrivateRoutes() {
    initAuthState();
    const token: any = AuthUtils.getIdTokenPayload();
    const blacklisted: string = token["custom:blacklisted"];
    if (blacklisted === "true") {
      setRoutesType("blacklisted");
    } else {
      setRoutesType("private");
    }
  }

  const isUserLogged = useAppSelector((state) => state.auth.logged);

  useEffect(() => {
    if (userWasLogged !== isUserLogged) {
      if (isUserLogged) {
        const token: any = AuthUtils.getIdTokenPayload();
        const blacklisted: string = token["custom:blacklisted"];
        if (blacklisted === "true") {
          setRoutesType("blacklisted");
        } else {
          setRoutesType("private");
        }
      } else {
        // user was logged out
        setRoutesType("public");
      }
    }
    setUserWasLogged(isUserLogged);
  }, [isUserLogged]);

  return (
    <div className="App">
      <Topbar path={routesType} />
      {routesType === "private" ? <LateralMenu {...props} /> : <></>}
      <Switch>
        {_.map(accessibleRoutes, (route: any, idx: number) => {
          return (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        })}
        {routesType === "private" && <Redirect from="*" to="/" />}
        {routesType === "public" && <Redirect from="*" to="/login" />}
        {routesType === "blacklisted" && (
          <Redirect from="*" to="/blacklisted" />
        )}
      </Switch>
    </div>
  );
}

export default App;
