import { ErrorOutline } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  Link,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { useState } from "react";

type LoginFormProps = {
  onLogin: (username: string, password: string, rememberMe: boolean) => void;
  onForgotPasswordClicked: () => void;
  loginError: string;
};

function LoginForm(props: LoginFormProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="cw-login-form">
      <div className="cw-content">
        <h1>Login</h1>
        <>
          <div className="cw-input-box">
            <InputLabel htmlFor="cw-username-input">
              Email address <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              className="cw-username-input"
              placeholder="Email address"
              variant="outlined"
              minRows={5}
              style={{ marginRight: "0.7vw" }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              error={props.loginError ? true : false}
            />
          </div>
          <div className="cw-input-box">
            <InputLabel htmlFor="cw-password-input">
              Password <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <TextField
              className="cw-password-input"
              placeholder="Password"
              variant="outlined"
              minRows={5}
              style={{ marginRight: "0.7vw" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              error={props.loginError.length ? true : false}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="cw-input-box cw-checkbox-link">
            <div style={{ flex: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    inputProps={{ "aria-label": "Remember Me Checkbox" }}
                    style={{ color: rememberMe ? "green" : "lightgray" }}
                  />
                }
                label="Remember Me"
              />
            </div>
            <div style={{ flex: 1, textAlign: "right" }}>
              <Link href="#" onClick={props.onForgotPasswordClicked}>Forgot Password?</Link>
            </div>
          </div>
          <div
            className="cw-input-box cw-login-button"
          >
            <Button
              variant="contained"
              color="primary"
              disabled={!username || !password}
              onClick={() => props.onLogin(username, password, rememberMe)}
            >
              Login
            </Button>
            {props.loginError && (
              <InputLabel>
                <ErrorOutline />
                {props.loginError}
              </InputLabel>
            )}
          </div>
        </>
      </div>
    </div>
  );
}

export default LoginForm;
