import jwt_decode from "jwt-decode";
import * as Constants from "../constants";
import moment from "moment";
import { environment } from "../environments/environment.prod";

class AuthUtils {
  getAccessToken() {
    return localStorage.getItem(Constants.jwt.AUTHTOKEN_NAME) || null;
  }

  getIdToken() {
    return localStorage.getItem(Constants.jwt.IDAUTHTOKEN_NAME) || null;
  }

  setToken(token: any) {
    localStorage.setItem(Constants.jwt.AUTHTOKEN_NAME, token);
  }

  deleteAccessToken() {
    localStorage.removeItem(Constants.jwt.AUTHTOKEN_NAME);
  }

  performLogout() {
    const keysToDelete = Object.keys(localStorage).filter(
      (key) => !key.startsWith("CognitoIdentityServiceProvider")
    );
    keysToDelete.forEach((key) => localStorage.removeItem(key));
  }

  getAccessTokenPayload() {
    try {
      const token = this.getAccessToken();
      const payload = jwt_decode(token!);
      return payload;
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  getIdTokenPayload(): any {
    try {
      const token = this.getIdToken();
      const payload = jwt_decode(token!);
      return payload;
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  getTokenExpirationDate(ishuman = false) {
    const token: any = this.getIdTokenPayload(); //either one of them is fine, because they have same expiration time
    let expDate = null;
    if (token) {
      expDate = ishuman ? moment(token.exp * 1000).format() : token.exp;
    }
    return expDate;
  }

  getTokenTimeToExpire() {
    const exp = this.getTokenExpirationDate();
    return exp ? moment(exp * 1000).fromNow(true) : 0;
  }

  checkToken() {
    try {
      const token = this.getAccessToken();
      if (token) {
        const expired = this.isTokenExpired();
        if (expired) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  isTokenExpiresIn(secs: number) {
    try {
      const expDate = this.getTokenExpirationDate();
      if (!expDate) return true;
      const time = moment().add(secs, "s").unix();
      return expDate < time;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  isTokenExpired() {
    return this.isTokenExpiresIn(0);
  }

  setMfaMethod(keyPrefix: string, mfaMethod: string) {
    localStorage.setItem(keyPrefix + ".mfaMethod", mfaMethod);
  }

  removeMfaMethod(keyPrefix: string) {
    localStorage.removeItem(keyPrefix + ".mfaMethod");
  }

  getMfaMethodByUser(username: string) {
    const mfaMethodByName = Object.keys(localStorage).filter(
      (key) =>
        key.startsWith("CognitoIdentityServiceProvider") &&
        key.endsWith(username + ".mfaMethod")
    )[0];
    return localStorage.getItem(mfaMethodByName!) || "";
  }

  getDeviceKeyByUser(username: string) {
    const deviceKeyName = Object.keys(localStorage).filter(
      (key) =>
        key.startsWith("CognitoIdentityServiceProvider") &&
        key.endsWith(username + ".deviceKey")
    )[0];
    return localStorage.getItem(deviceKeyName!) || "";
  }

  getDeviceKey() {
    const deviceKeyName = Object.keys(localStorage).filter(
      (key) =>
        key.startsWith("CognitoIdentityServiceProvider") &&
        key.endsWith("deviceKey")
    )[0];
    return localStorage.getItem(deviceKeyName!) || "";
  }

  setRememberMe(keyPrefix: string, rememberMe: boolean) {
    if (rememberMe) {
      localStorage.setItem(`${keyPrefix}.rememberMe`, "true");
    } else {
      localStorage.removeItem(`${keyPrefix}.rememberMe`);
    }
  }

  getRememberMe() {
    // In this function we don't try to get the keyPrefix out of the current user logged with amplify
    // as it may be logged off, and we may be not able to retrieve this information
    const idTokenPayload = this.getIdTokenPayload();
    const aud = idTokenPayload.aud;
    const username = idTokenPayload["cognito:username"];
    const keyPrefix = `CognitoIdentityServiceProvider.${aud}.${username}`;
    const rememberMe = localStorage.getItem(`${keyPrefix}.rememberMe`);
    return rememberMe ? true : false;
  }

  async checkYubikeyOTP(otp: string) {
    try {
      const result = await fetch(
        `${environment.regApiGatewayUrl}yubikey?otp=${otp}`
      );
      if (result && result.status === 200) {
        const resultText = await result.text();
        const response = JSON.parse(resultText);
        return response.isOtpValid;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  }
  

  getMfaMethodFromUser(user: any): string {
    if(user.preferredMFA === "SOFTWARE_TOKEN_MFA") return "TOTP";
    if(user.attributes["custom:yubikeyId"]) return "Yubikey";
    return "";
  }
}

const authUtils = new AuthUtils();
export default authUtils;
