


import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import "../../css/Blacklisted.css";

function Blacklisted() {
    const [openBackDrop, setOpenBackDrop] = useState(false);

    return (
        <div className="page-panel blacklisted-page">
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={openBackDrop}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
            <div className ="message-card">
                <h1>
                    You have been blacklisted
                </h1>
                <p>
                    Please contact your organization administrator  
                </p>
            </div>
        </div>
    )
}

export default Blacklisted