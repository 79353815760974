import Api from "./api";
import BBPromise from "bluebird";
import axios, { AxiosInstance } from "axios";
import { environment } from "../environments/environment.prod";
import _ from "lodash";
import authUtils from "../utils/authUtils";

class DigestsApi extends Api {
  configClient(additioalHeaders: any = null) {
    const token = authUtils.getIdToken();
    let headers = { Authorization: "Bearer " + token };
    if (additioalHeaders) {
      _.merge(headers, additioalHeaders);
    }

    const client: AxiosInstance = axios.create({
      baseURL: environment.regApiGatewayUrl,
      timeout: 30000,
      headers,
    });
    return client;
  }

  getClient(callback: (client: AxiosInstance) => void): void {
    const isPrivate = true;
    super.getClient(callback, isPrivate);
  }

  async updateBlacklistedValueOfDigests(
    username: string,
    value: boolean
  ): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = "/digests-blacklisted/";
      const params = { username: username, value: value };

      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .post(url, { ...params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async getDigestsWithFilter(filter: any): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = "/digests-filter/";
      const params = { filter: filter };

      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .post(url, { ...params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async getDigestListBytenantId(tenantID: String): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = "/digests/" + tenantID;
      const params = {};

      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .get(url, { params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async getDigestListByUserNameWithFilter(
    tenantID: String,
    userName: String,
    filter: any
  ): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = "/digests/" + tenantID + "/" + userName;
      const params = { filter: filter };

      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .post(url, { ...params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async getDigestListByUserName(
    tenantID: String,
    userName: String
  ): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = "/digests/" + tenantID + "/" + userName;
      const params = {};

      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .get(url, { params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async getDigestByDigest(hash: any): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = "/digests";
      const params = { hash: hash };

      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .post(url, { ...params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }
}

export default new DigestsApi();
