import { Fragment, useEffect, useState } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import EditIcon from "@mui/icons-material/Edit";

import _ from "lodash";
import UsersApi from "../../api/usersApi";
import { StripeInfo } from "../../interfaces/stripeInfo";
import { IUser } from "../../interfaces/user";

//import { environment } from "../../environments/environment.prod";
import { Auth } from "aws-amplify";
import StripeApi from "../../api/stripeApi";
import QRCode from "qrcode.react";
import AuthUtils from "../../utils/authUtils";
import { useAppDispatch } from "../../store/hooks";
import { logout } from "../../store/reducers/authSlice";

const emptyStripeInfo: StripeInfo = {
  phone: "",
  address: "",
  country: "",
  city: "",
  currentPeriodEnd: "",
  subscriptionType: "",
  cardBrand: "",
  cardLast4: "",
};

const emptyUser: IUser = {
  blacklisted: "",
  user_name: "",
  display_name: "",
  email: "",
  user_role: "",
  tenant_name: "",
  tfa: "",
  organization_name: "",
  tenant_id: "",
  subscription_type: "",
  expiration_date: "",
  tenant_tier: "",
  phone: "",
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

const AccountTable = (props: any) => {
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { trigger } = props;
  const [user, setUser] = useState(emptyUser);
  const [stripeInfo, setStripeInfo] = useState(emptyStripeInfo);
  const [openModal, setOpenModal] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [modalType, setModalType] = useState("");
  const [qrCodeData, setQRCodeData] = useState("");
  const [code, setCode] = useState("");
  const [yubikeyCode, setYubikeyCode] = useState("");
  const [yubikeyActivationMode, setYubikeyActivationMode] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);

  const dispatch = useAppDispatch();

  const handleClose = () => {
    setOpenModal(false);
    setNewUserName("");
  };

  useEffect(() => {
    setOpenBackDrop(true);

    Auth.currentAuthenticatedUser()
      .then((response: any) => {
        const userInfo = response;
        // Auth.fetchDevices().then((devices: any) => {
        //   devices
        //     .map((device: any) => device.id)
        //     .includes(AuthUtils.getDeviceKeyByUser(userInfo.username))
        //     ? setRememberDevice(true)
        //     : setRememberDevice(false)
        // }
        // );
        const newUser = {
          blacklisted: userInfo.attributes["custom:blacklisted"],
          user_role: userInfo.attributes["custom:userRole"],
          tenant_id: userInfo.attributes["custom:tenantId"],
          yubikeyId: userInfo.attributes["custom:yubikeyId"],
          email: userInfo.attributes["email"],
          user_name: userInfo["username"],
          tenant_name: localStorage.getItem("tenantName"),
          tenant_tier: localStorage.getItem("tenantTier"),
          tfa: userInfo.preferredMFA,
        };
        setUser(newUser);
        if (newUser.user_role === "TenantAdmin") {
          StripeApi.getStripeInfo(newUser.tenant_id)
            .then((response: any) => {
              // console.log(response.data.message)
              let endDate = new Date(
                response.data.message.subscriptionInfo.currentPeriodEnd * 1000
              );
              // let separateSubscriptionType =
              //   response.data.message.priceLookup_key.split("-");
              let stripeData = {
                phone: response.data.message.customerInfo.phone,
                city: response.data.message.customerInfo.address.city,
                country: response.data.message.customerInfo.address.country,
                address: response.data.message.customerInfo.address.line1,
                currentPeriodEnd: endDate.toString(),
                subscriptionType: "Codewallet",
                // "Codewallet " +
                // separateSubscriptionType[0].charAt(0).toUpperCase() +
                // separateSubscriptionType[0].slice(1) +
                // " " +
                // separateSubscriptionType[1].charAt(0).toUpperCase() +
                // separateSubscriptionType[1].slice(1),
                cardBrand: response.data.message.paymentMethodInfo.brand,
                cardLast4: response.data.message.paymentMethodInfo.last4,
              };
              setStripeInfo(stripeData);
            })
            .catch((error: any) => {
              console.log(error);
            })
            .finally(() => {
              setOpenBackDrop(false);
            });
        } else {
          setOpenBackDrop(false);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [trigger]);

  const openUserNameModal = () => {
    setOpenModal(true);
    setModalType("user_name");
  };
  const open2FAModal = async () => {
    if (user.tfa === "NOMFA") {
      // setOpenModal(true);
      // setModalType("2FA");
      setupTOTP();
    } else {
      //remove 2Fa from user
      remove2FA();
    }
  };

  async function remove2FA() {
    try {
      enableSoftwareMFA(false);
      if(rememberDevice) handleRememberDevice(false);
      const newUser = { ...user, tfa: "NOMFA" };
      setUser(newUser);
    } catch (error) {
      console.log("Error removing 2FA", error);
    }
  }

  async function setupTOTP() {
    try {
      const loggedUser = await Auth.currentAuthenticatedUser();
      const authCode = await Auth.setupTOTP(loggedUser);
      const QRCode =
        "otpauth://totp/AWSCognito:" +
        user.user_name +
        "?secret=" +
        authCode +
        "&issuer=Cognito";
      setQRCodeData(QRCode);
      setYubikeyActivationMode(false);
    } catch (error) {
      console.log("Error setting up TOTP", error);
    }
  }

  async function activate2FA() {
    try {
      const loggedUser = await Auth.currentAuthenticatedUser();
      const verifiedUser = await Auth.verifyTotpToken(
        loggedUser,
        code.toString()
      );
      if (verifiedUser && (verifiedUser as any)["Status"] === "SUCCESS") {
        enableSoftwareMFA(true);
        const newLoggedUser = await Auth.currentAuthenticatedUser();
        const newUser = { ...user, tfa: newLoggedUser.preferredMFA };
        setUser(newUser);
        setQRCodeData("");
      }
    } catch (error) {
      console.log("Error activating TOTP", error);
    }
  }

  const openYubikeyModal = () => {
    if (!user.yubikeyId) {
      // setOpenModal(true);
      // setModalType("2FA");
      setupYubikey();
    } else {
      //remove 2Fa from user
      removeYubikey();
    }
  };

  async function removeYubikey() {
    try {
      try {
        setYubikeyId("");
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      console.log("Error removing Yubikey", error);
    }
  }

  async function setupYubikey() {
    setYubikeyActivationMode(true);
    setQRCodeData("");
  }

  async function activateYubikey() {
    try {
      const checkOTPvalidity = await AuthUtils.checkYubikeyOTP(yubikeyCode);
      if (!checkOTPvalidity) {
        throw new Error("OTP not valid");
      }

      try {
        setYubikeyId(yubikeyCode.slice(0, -32));
      } catch (err) {
        console.log(err);
      }
    } catch (error) {
      console.log("Error setting up Yubikey", error);
    }
    setYubikeyActivationMode(false);
  }

  const enableSoftwareMFA = async (checked: boolean) => {
    setOpenBackDrop(true);
    const attributes = [
      {
        Name: "preferred_mfa",
        Value: checked ? "true" : "false",
      },
    ];
    UsersApi.updateUser(user.user_name, attributes)
      .then(async (response: any) => {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        const newUser = { ...user, tfa: checked ? "TOTP" : "NOMFA" };
        setUser(newUser);
        setNewUserName("");
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setOpenBackDrop(false);
        handleClose();
      });
  };

  const changeUserName = (username: string) => {
    setOpenBackDrop(true);
    const attributes = [
      {
        Name: "user_name",
        Value: newUserName,
      },
    ];
    UsersApi.updateUser(username, attributes)
      .then((response: any) => {
        let newUser: any = _.cloneDeep(user);
        newUser.user_name = newUserName;
        setUser(newUser);
        setNewUserName("");
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setOpenBackDrop(false);
        handleClose();
      });
  };

  const setYubikeyId = (newYubikeyId: string) => {
    setOpenBackDrop(true);
    const attributes = [
      {
        Name: "custom:yubikeyId",
        Value: newYubikeyId,
      },
    ];
    UsersApi.updateUser(user.user_name, attributes)
      .then(async (response: any) => {
        await Auth.currentAuthenticatedUser({ bypassCache: true });
        let newUser: any = { ...user, yubikeyId: newYubikeyId };
        setUser(newUser);
      }) 
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setOpenBackDrop(false);
        if(rememberDevice) handleRememberDevice(false);
      })
  }

  const sample = [
    {
      name: "User Info",
      detail: [
        {
          key: "User Name",
          value: user.user_name,
          // onClickFunction: openUserNameModal,
        },
        { key: "Email", value: user.email, onClickFunction: undefined },
        {
          key: "2FA",
          value: user.tfa === "NOMFA" ? "NOT ACTIVE" : "ACTIVE",
          onClickFunction: open2FAModal,
        },
        {
          key: "Yubikey 2FA",
          value: !user.yubikeyId ? "NOT ACTIVE" : "ACTIVE",
          onClickFunction: openYubikeyModal,
        },
      ],
    },
    {
      name: "Organization Info",
      detail: [
        {
          key: "Tenant Name",
          value: user.tenant_name,
          onClickFunction: undefined,
        },
        { key: "User Role", value: user.user_role, onClickFunction: undefined },
      ],
    },
    {
      name: "Subscription Info",
      detail: [
        {
          key: "Subscription Type",
          value: stripeInfo.subscriptionType,
          onClickFunction: undefined,
        },
        {
          key: "Next bill",
          value: stripeInfo.currentPeriodEnd,
          onClickFunction: undefined,
        },
        { key: "Phone", value: stripeInfo.phone, onClickFunction: undefined },
        {
          key: "Address",
          value:
            stripeInfo.address +
            " " +
            stripeInfo.city +
            " " +
            stripeInfo.country,
          onClickFunction: undefined,
        },
        {
          key: "Payment Method",
          value:
            stripeInfo.cardBrand +
            " **** **** **** **** " +
            stripeInfo.cardLast4,
          onClickFunction: undefined,
        },
      ],
    },
  ];
  if (user.user_role !== "TenantAdmin") {
    sample.pop();
  }
  if (user.tenant_tier === "Platinum") {
    sample[1].detail[0].key = "Organization Name";
  }

  async function handleRememberDevice(checked: boolean) {
    const loggedUser = await Auth.currentAuthenticatedUser();
    const keyPrefix = `${loggedUser.keyPrefix}.${loggedUser.username}`;

    Auth.configure({
      authenticationFlowType: "USER_SRP_AUTH",
    });
    try {
      if (checked) {
        await Auth.rememberDevice();
        AuthUtils.setMfaMethod(keyPrefix, user.yubikeyId ? "yubikey" : "totp");
      } else {
        await Auth.forgetDevice();
        await Auth.signOut();
        AuthUtils.performLogout();
        AuthUtils.removeMfaMethod(keyPrefix);
        dispatch(logout());
        // TEMPORARY AS A WORKAROUND FOR THE BUG IN THE AWS AMPLIFY LIBRARY
        // BUG IS: When calling Auth.forgetDevice() the library is calling Auth.signOut() internally because of an error with refreshSession() function
        window.location.reload(); 
      }
      setRememberDevice(checked);
    } catch (error) {
      console.log("Error remembering device", error);
      await Auth.signOut();
      AuthUtils.performLogout();
      AuthUtils.removeMfaMethod(keyPrefix);
      dispatch(logout());
      // TEMPORARY AS A WORKAROUND FOR THE BUG IN THE AWS AMPLIFY LIBRARY
      // BUG IS: When calling Auth.forgetDevice() the library is calling Auth.signOut() internally because of an error with refreshSession() function
      window.location.reload();  
    }
    Auth.configure({
      authenticationFlowType: "CUSTOM_AUTH",
    });
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-add-user-title"
        aria-describedby="modal-add-user-description"
      >
        <Box sx={style} style={{ textAlign: "center" }}>
          <Typography
            id="modal-add-user-title"
            variant="h6"
            component="h2"
            style={{ width: "100%", marginBottom: "10%" }}
          >
            {modalType === "user_name"
              ? "Changhe your userName"
              : "Do you want to activate your second factor authentication?"}
          </Typography>
          {modalType === "user_name" ? (
            <div id="modal-add-user-description">
              <Grid
                container
                spacing={2}
                direction="column"
                alignItems="center"
                justifyContent="top"
                style={{ height: "100%" }}
              >
                <Grid item xs={3} style={{ width: "80%" }}>
                  <TextField
                    value={newUserName}
                    onChange={(e) => setNewUserName(e.target.value)}
                    className="add-user-input"
                    placeholder="Username"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} style={{ width: "100%", marginTop: "10%" }}>
                  <Button
                    style={{ marginLeft: "5%" }}
                    variant="contained"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: "5%", marginRight: "10%" }}
                    variant="contained"
                    color="success"
                    onClick={() => changeUserName(user.user_name)}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Modal>
      {sample.map((item) => (
        <TableContainer
          component={Paper}
          style={{
            width: "70%",
            marginBottom: "2%",
            marginLeft: "15%",
            marginTop: "2%",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    padding: "3%",
                    backgroundImage: "linear-gradient(to right,#001b35, #036)",
                    color: "white",
                    fontSize: "large",
                  }}
                >
                  {item.name}
                </TableCell>
                <TableCell
                  style={{
                    padding: "3%",
                    backgroundImage: "linear-gradient(to right,#036, #0071bc)",
                  }}
                ></TableCell>
                <TableCell
                  style={{
                    padding: "3%",
                    backgroundImage:
                      "linear-gradient(to right,#0071bc, #29abe2)",
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Fragment>
                {item.detail.map(({ key, value, onClickFunction }) => {
                  if (key === "2FA" && user.yubikeyId) return <></>;
                  if (key === "Yubikey 2FA" && user.tfa !== "NOMFA")
                    return <></>;
                  return (
                    <>
                      <TableRow>
                        <TableCell
                          variant="head"
                          align="center"
                          style={{ width: "30%" }}
                        >
                          {key}
                        </TableCell>
                        <TableCell align="center" style={{ width: "50%" }}>
                          {value}
                        </TableCell>
                        <TableCell>
                          {onClickFunction !== undefined ? (
                            <button
                              style={{
                                position: "relative",
                                right: "-70%",
                                backgroundColor: "transparent",
                                border: "transparent",
                                cursor: "pointer",
                              }}
                              onClick={onClickFunction}
                            >
                              <EditIcon
                                style={{ color: "#036" }}
                                fontSize="small"
                              />
                            </button>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                      {key === "2FA" && !user.yubikeyId && qrCodeData && (
                        <>
                          <TableRow>
                            <TableCell
                              variant="head"
                              align="center"
                              style={{ width: "30%" }}
                            >
                              QR Code
                            </TableCell>
                            <TableCell align="center" style={{ width: "50%" }}>
                              <QRCode value={qrCodeData} />
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell
                              variant="head"
                              align="center"
                              style={{ width: "30%" }}
                            >
                              Code from your OTP app
                            </TableCell>
                            <TableCell align="center" style={{ width: "50%" }}>
                              <TextField
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                className="add-user-input"
                                placeholder="Code"
                                variant="outlined"
                                fullWidth
                              />
                              <Button
                                style={{
                                  marginLeft: "5%",
                                  marginRight: "10%",
                                }}
                                variant="contained"
                                color="success"
                                onClick={() => activate2FA()}
                              >
                                Activate
                              </Button>
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                      {key === "Yubikey 2FA" &&
                        user.tfa === "NOMFA" &&
                        yubikeyActivationMode && (
                          <>
                            <TableRow>
                              <TableCell
                                variant="head"
                                align="center"
                                style={{ width: "30%" }}
                              >
                                Yubikey ID
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "50%" }}
                              >
                                <TextField
                                  value={yubikeyCode}
                                  onChange={(e) =>
                                    setYubikeyCode(e.target.value)
                                  }
                                  className="add-user-input"
                                  placeholder="Yubikey ID"
                                  variant="outlined"
                                  fullWidth
                                />
                                <Button
                                  style={{
                                    marginLeft: "5%",
                                    marginRight: "10%",
                                  }}
                                  variant="contained"
                                  color="success"
                                  onClick={() => activateYubikey()}
                                >
                                  Activate
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      {(((key === "2FA" && user.tfa !== "NOMFA") ||
                        (key === "Yubikey 2FA" && user.yubikeyId)) &&
                        false && ( // TODO: Amplify + CUSTOM_AUTH is buggy when an user wants to remember the Device
                                   // Basically it won't work for TOTP users
                          <>
                            <TableRow>
                              <TableCell
                                variant="head"
                                align="center"
                                style={{ width: "30%" }}
                              >
                                Remember this browser
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "50%" }}
                              >
                                <input
                                  type="checkbox"
                                  checked={rememberDevice}
                                  onChange={(e) =>
                                    handleRememberDevice(e.target.checked)
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          </>
                        ))}
                    </>
                  );
                })}
              </Fragment>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
};

export default AccountTable;
