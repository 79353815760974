import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Button } from "@mui/material";
import DigestsApi from "../../api/digestsApi";
import "../../css/checkHash.css";

function CheckHash() {


    const [digestSearch, setDigestSearch] = useState("");
    const [searchFinished, setSearchFinished] = useState(false);
    const [responseSearch, setResponseSearch] = useState("");
    const [openBackDrop, setOpenBackDrop] = useState(false);
  
  
    const checkDigest=(digestInfo:any)=>{
  
     
        let response="";   
        
        if(digestInfo["blacklisted"]===true){response="owner of this digest is blacklisted";}
        else if(digestInfo["deprecated"]===true){response="the digest is deprecated at "+digestInfo["deprecatedAt"] ;}
        else {
            console.log(digestInfo);
            let createAt=new Date(1000*Number(digestInfo["createdAt"])).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })  
            response="Owner: "+digestInfo["userName"]+",\n created at: "+createAt+",\nversion: "+digestInfo["signedVersion"];
        }
  
        return response;
        
    }

    const searchDigestFunction = () => {

        let hash=digestSearch
        DigestsApi.getDigestByDigest(hash.replace(/ /g,''))
          .then((response: any) => {
    
            let responseCheck=checkDigest(response.data.message);
         
            setResponseSearch(responseCheck);
            setDigestSearch("");
            setSearchFinished(true);
    
          })
          .catch((error: any) => {
            setResponseSearch("digest not found");
            setDigestSearch("");
            setSearchFinished(true)
            console.log(error);
          })
          .finally(() => {
            setOpenBackDrop(false);
          });
    }
  
    return (
        <div className="page-panel">
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={openBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>


            <Grid
                marginTop={0}
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justifyContent="center"
                style={{ width: "100%"}}>
    
                <Grid item xs={12}>
                    {searchFinished === false? (
                            <TextField
                                className="search-hash"
                                placeholder="Check Hash"
                                variant="outlined"
                                multiline
                                minRows={5}
                                fullWidth 
                                style={{ marginLeft:"0.7vw"}}
                                value={digestSearch}
                                onChange={(e) => setDigestSearch(e.target.value)}
                                InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}/>
                        ):(
                            <p style={{ textAlign:"center"}}>{responseSearch}</p>
                        )}
                </Grid>

                <Grid item xs={6} >
                    {searchFinished === false? (
                        <Button className="button-search" fullWidth variant="contained" onClick={() => searchDigestFunction()}>Check Hash</Button>
                    ):(
                        <Button className="button-search" fullWidth variant="contained" onClick={() => setSearchFinished(false)}>New Check</Button>
                    )}
                </Grid>
        
            </Grid>
        </div>
      );
}


export default CheckHash;




