import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { environment } from "../../environments/environment.prod";

function Welcome() {
  const [params, setParams] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tenantID = queryParams.get("tenantId");

    const url =
      environment.regApiGatewayUrl + "tenant-confirmation?tenantId=" + tenantID;
    axios.get(url).finally(() => {
      setParams(false);
    });
  }, []);

  return (
    <div className="page-panel welcome-page">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={params}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ height: "100%" }}
      >
        <Grid item xs={3}></Grid>

        <Grid item xs={3}>
          <h1 className="welcome-paragraph">
            We are setting up your resources. You will receive an email when
            everything is ready!
          </h1>
          <p className="welcome-paragraph">You can close this page.</p>
        </Grid>
      </Grid>
    </div>
  );
}

export default Welcome;
