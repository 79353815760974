import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DigestsApi from "../../api/digestsApi";
import { IDigest } from "../../interfaces/digest";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
//import _, { isUndefined } from "lodash";
import AuthUtils from "../../utils/authUtils";




// let emptyDigest: IDigest= {
//   digestId: "",
//   digest: "",
//   userName:"",
//   tenantId: "",
//   blacklisted: false,
//   deprecated:  false,
//   createdAt: "",
//   keys: "",
//   s3name:"",
// }

export default function DigetsTable(props: any) {

  const { filter, trigger, setDigestsParent } = props;
  const [digests, setDigests] = useState<IDigest[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);


  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [orderBy, setOrderBy] = useState({
    id: "user_name",
    type: "asc",
  });


  useEffect(() => {
    setOpenBackDrop(true);

    const payloadToken: any= AuthUtils.getAccessTokenPayload();
    const tenantID=payloadToken["cognito:groups"][0];
    const userData: any = AuthUtils.getIdTokenPayload();
    

    if(userData["custom:userRole"] ==="TenantAdmin"){

      setIsAdmin(true);
      DigestsApi.getDigestsWithFilter(filter)
      .then((response: any) => {
        setDigests(response.data.message);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setOpenBackDrop(false);
      });


    }
    else{

      const payloadToken: any= AuthUtils.getAccessTokenPayload();
      const userName=payloadToken["username"];
      
      DigestsApi.getDigestListByUserNameWithFilter(tenantID,userName,filter)
      //DigestsApi.getDigestListByUserName(tenantID,userName)
      .then((response: any) => {
        setDigests(response.data.message);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => {
        setOpenBackDrop(false);
      });
    }


   


  }, [trigger]);
 
  // useEffect(() => {
  //   let orderedUsers;
  //   orderedUsers = _.orderBy(
  //     digests,
  //     [orderBy.id],
  //     [(orderBy.type as "asc") || "desc"]
  //   );
  //   setDigests(orderedUsers);
  // }, [orderBy]);

  const handleSortBy = (id: string) => {
    const newOrderBy = {
      id: id,
      type: orderBy.id !== id ? "asc" : orderBy.type === "asc" ? "desc" : "asc",
    };
    setOrderBy(newOrderBy);
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

     
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>

              <TableCell className="user-table-head">ID</TableCell>
              <TableCell className="user-table-head">Hash</TableCell>
              <TableCell className="user-table-head">{isAdmin === true?"User Name": ""}</TableCell>
             

              <TableCell
                className={
                  "user-table-head allow-order " +
                  (orderBy.id === "createdAt" ? "selected" : "")
                }
                onClick={() => handleSortBy("createdAt")}
              >
                Create At
                {orderBy["id"] === "createdAt" ? (
                  orderBy["type"] === "desc" ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                ) : (
                  <ArrowDropDownIcon />
                )}
              </TableCell>

              <TableCell className="user-table-head">Blacklisted</TableCell>
              <TableCell className="user-table-head">Deprecated</TableCell>
              <TableCell className="user-table-head">Signed Version</TableCell>
              <TableCell className="user-table-head">Deprecated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {
              
              // .filter((digest: IDigest) => (filter.deprecated===false && filter.blacklisted===false) || 
              //                              (filter.blacklisted===true  && digest.blacklisted===true ) || 
              //                              (filter.deprcated===true  && digest.deprecated===true )
              // )
              // .filter(
              //   (digest: any) => (filter.value=="" || filter.type=="" ||
              //                    (filter.type=="digest" && digest.digest.startsWith(filter.value)) ||
              //                    (filter.type=="digestId" && digest.digestId.startsWith(filter.value)) || 
              //                    (filter.type=="userName" && digest.userName.startsWith(filter.value)) 
              //                    )
              // )
              digests.map((row: IDigest) => (

                <TableRow
                  key={row.digestId}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.digestId || ""}</TableCell>
                  <TableCell>{row.hash || ""}</TableCell>

                  <TableCell>{isAdmin === true? row.userName : ""}</TableCell>
                  <TableCell>{(new Date(1000*Number(row.createdAt))).toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' })  }</TableCell>
                  <TableCell>
                      {row.blacklisted === false? "False" : "True"}
                  </TableCell>
                  <TableCell>      
                      {row.deprecated === false? "False" : "True"}
                  </TableCell>
                  <TableCell>      
                      {row.signedVersion || ""}
                  </TableCell>
                  <TableCell>      
                      {row.deprecatedAt || ""}
                  </TableCell>

                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>


      

    </>
  );
}

