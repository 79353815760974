import { CircularProgress } from "@mui/material";
import { Auth } from "aws-amplify";
import { useState } from "react";
import LoginImage from "../../assets/img/login.png";
import Logo from "../../assets/img/logo.svg";
import "../../css/Login.css";
import { useAppDispatch } from "../../store/hooks";
import { login } from "../../store/reducers/authSlice";
import AuthUtils from "../../utils/authUtils";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import LoginForm from "../forms/LoginForm";
import ResetCodeForm from "../forms/ResetCodeForm";
import ResetPasswordForm from "../forms/ResetPasswordForm";
import SoftwareTokenMFAForm from "../forms/SoftwareTokenMFAForm";

enum Stage {
  Login,
  ForgotPassword,
  ResetPassword,
  ResetCode,
  SoftwareTokenMFA,
}

function Login() {
  const dispatch = useAppDispatch();
  const [stage, setStage] = useState(Stage.Login);
  const [user, setUser] = useState<any>(null);
  const [username, setUsername] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [MFAError, setMFAError] = useState("");
  const [resetCodeError, setResetCodeError] = useState("");

  function resetStage() {
    setStage(Stage.Login);
    setLoginError("");
    setUsername("");
    setOldPassword("");
    setResetCodeError("");
  }

  function renderStage(): JSX.Element {
    switch (stage) {
      case Stage.Login:
        return (
          <LoginForm
            onLogin={handleLogin}
            onForgotPasswordClicked={() => setStage(Stage.ForgotPassword)}
            loginError={loginError}
          />
        );
      case Stage.ForgotPassword:
        return (
          <ForgotPasswordForm
            onSubmit={handleForgotPassword}
            onBack={() => resetStage()}
            forgotPasswordError={forgotPasswordError}
          />
        );
      case Stage.ResetPassword:
        return (
          <ResetPasswordForm
            onSubmit={handleResetPassword}
            onBack={() => resetStage()}
            oldPassword={oldPassword}
            resetPasswordError={resetPasswordError}
          />
        );
      case Stage.ResetCode:
        return (
          <ResetCodeForm
            onSubmit={handleResetCode}
            onBack={() => resetStage()}
            resetCodeError={resetCodeError}
          />
        );
      case Stage.SoftwareTokenMFA:
        return (
          <SoftwareTokenMFAForm
            onSubmit={handleSoftwareTokenMFA}
            onBack={() => resetStage()}
            MFAError={MFAError}
          />
        );
      default:
        return <></>;
    }
  }

  async function userLogIn() {
    const user = await Auth.currentAuthenticatedUser();
    if (user) {
      dispatch(
        login({
          access_token: user.signInUserSession.accessToken.jwtToken,
          id_token: user.signInUserSession.idToken.jwtToken,
        })
      );
      localStorage.setItem(
        "access_token",
        user.signInUserSession.accessToken.jwtToken
      );
      localStorage.setItem("id_token", user.signInUserSession.idToken.jwtToken);
    }
  }

  async function handleLogin(
    username: string,
    password: string,
    rememberMe: boolean
  ) {
    setLoading(true);
    try {
      setLoginError("");
      // const mfaMethod = AuthUtils.getMfaMethodByUser(username);
      // Auth.configure({
      //   authenticationFlowType: mfaMethod === "totp" ? "USER_SRP_AUTH" : "CUSTOM_AUTH",
      // });


      const user = await Auth.signIn(username, password, {
        deviceKey: AuthUtils.getDeviceKeyByUser(username),
      });
      setUser(user);

      const keyPrefix = `${user.keyPrefix}.${user.username}`;
      AuthUtils.setRememberMe(keyPrefix, rememberMe);

      switch (user.challengeName) {
        case "NEW_PASSWORD_REQUIRED":
          setOldPassword(password);
          setStage(Stage.ResetPassword);
          break;
        case "CUSTOM_CHALLENGE":
        case "SOFTWARE_TOKEN_MFA":
          setStage(Stage.SoftwareTokenMFA);
          break;
        case undefined:
          userLogIn();
          break;
        default:
          throw new Error("Unknown challenge name");
      }
    } catch (error: any) {
      if (
        error.name === "UserNotFoundException" ||
        error.name === "NotAuthorizedException"
      ) {
        setLoginError("Invalid email address or password");
      } else {
        console.log("error signing in", error);
      }
    }
    setLoading(false);
  }

  async function handleResetPassword(newPassword: string) {
    setLoading(true);
    try {
      const result = await Auth.completeNewPassword(user, newPassword);
      userLogIn();
    } catch (error: any) {
      console.log("error resetting password", error);
      if (error.name === "NotAuthorizedException") {
        setResetPasswordError(
          "Session expired. You have to log again with your temporary credentials"
        );
      }
    }
    setLoading(false);
  }

  async function handleForgotPassword(username: string) {
    setLoading(true);
    try {
      await Auth.forgotPassword(username);
      // console.log("forgot password initiated");
      setUsername(username);
      setStage(Stage.ResetCode);
    } catch (error) {
      console.log("error initiating forgot password", error);
      setForgotPasswordError("Invalid email address");
    }
    setLoading(false);
  }

  async function handleResetCode(code: string, newPassword: string) {
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      // console.log("password reset");
      resetStage();
    } catch (error) {
      console.log("error resetting password", error);
      setResetCodeError("Invalid code");
    }
    setLoading(false);
  }

  async function handleSoftwareTokenMFA(code: string) {
    setLoading(true);
    try {
      let verified_user: any;
      if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
      verified_user = await Auth.confirmSignIn(
        user,
        code,
        user.challengeName
      );
      } else if (user.challengeName === "CUSTOM_CHALLENGE") {
        verified_user = await Auth.sendCustomChallengeAnswer(user, code);
      }

      if (verified_user) {
        userLogIn();
      }
    } catch (error: any) {
      console.log("error confirming sign in", error);
      if (error.name === "ExpiredCodeException") {
        setMFAError(
          "You have already used this token. Please, generate or wait for a new one"
        );
      } else {
        setMFAError("Invalid token");
      }
    }
    setLoading(false);
  }

  return (
    <>
      <div
        className="onboarding"
        style={{ backgroundImage: `url(${LoginImage})` }}
      >
        <div className="cw-content">
          <div className="review">
            <h1 className="title titleheading--h1">
              Code Signing Made Simple.
            </h1>
            <p className="say-goodbye-to-code">
              Say goodbye to code signing headaches. Let Codewallet manage
              trusted certificates and signed code for you or your whole team.
            </p>
          </div>
          <div className="logo">
            <img className="image" src={Logo} alt="image 2" />
          </div>
        </div>
      </div>
      {loading && (
        <div
          className="cw-login-form"
          style={{ zIndex: 1000, backgroundColor: "#00000040" }}
        >
          <div className="cw-content">
            <CircularProgress
              style={{ display: "flex", alignSelf: "center" }}
            />
          </div>
        </div>
      )}
      {renderStage()}
    </>
  );
}

export default Login;
