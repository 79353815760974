import { useState } from "react";
import Grid from "@mui/material/Grid";
import AccountTable from "../tables/accountTable";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";



function Account() {
  const [openBackDrop, setOpenBackDrop] = useState(false);

  return (
    <div className="page-panel">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="top"
        style={{ height: "100%" }}
      >
        <Grid item xs={3} style={{ padding: "2px", width: "100%" }}>
          <AccountTable />
        </Grid>
      </Grid>
    </div>
  );
}

export default Account;
