import Api from "./api";
import BBPromise from "bluebird";
import axios, { AxiosInstance } from "axios";
import { environment } from "../environments/environment.prod";
import _ from "lodash";
import AuthUtils from "../utils/authUtils";

class StripeApi extends Api {
  configClient(additioalHeaders: any = null) {
    const token = AuthUtils.getIdToken();
    let headers = { Authorization: "Bearer " + token };
    if (additioalHeaders) {
      _.merge(headers, additioalHeaders);
    }

    const client: AxiosInstance = axios.create({
      baseURL: environment.regApiGatewayUrl,
      timeout: 30000,
      headers,
    });
    return client;
  }

  getClient(callback: (client: AxiosInstance) => void): void {
    const isPrivate = true;
    super.getClient(callback, isPrivate);
  }

  async getStripeInfo(tenant_id: any): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = `/stripe/${tenant_id}`;
      //const params = customer_id;
      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .get(url) //, { ...params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  // async getSubsription(tenant_id: string):BBPromise<any> {
  //     return new BBPromise((resolve, reject) => {
  //         const url = `/stripe/${tenant_id}/subscription`;
  //         //const params = subscription_id;
  //         this.getClient((axiosClient: AxiosInstance) => {
  //             axiosClient
  //             .get(url)//, { ...params })
  //             .then((response: any) => {
  //                 resolve(response);
  //             })
  //             .catch((error: any) => {
  //                 resolve(error);
  //             });
  //         });
  //     });
  // }

  // async getPaymentMethod(tenant_id: string):BBPromise<any> {
  //     return new BBPromise((resolve, reject) => {
  //         const url = `/stripe/${tenant_id}/paymentMethod`;
  //         //const params = paymentMethod_id;
  //         this.getClient((axiosClient: AxiosInstance) => {
  //             axiosClient
  //             .get(url)//, { ...params })
  //             .then((response: any) => {
  //                 resolve(response);
  //             })
  //             .catch((error: any) => {
  //                 resolve(error);
  //             });
  //         });
  //     });
  // }

  async updateCustomer(customer_id: string, attribute: any[]): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = `/stripe`;
      const params = attribute;
      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .put(url, { params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async updateSubscription(
    subscription_id: string,
    attribute: any[]
  ): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = `/stripe`;
      const params = attribute;
      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .put(url, { params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }

  async updatePaymentMethod(
    paymentMethod_id: string,
    attribute: any[]
  ): BBPromise<any> {
    return new BBPromise((resolve, reject) => {
      const url = `/stripe`;
      const params = attribute;
      this.getClient((axiosClient: AxiosInstance) => {
        axiosClient
          .put(url, { params })
          .then((response: any) => {
            resolve(response);
          })
          .catch((error: any) => {
            resolve(error);
          });
      });
    });
  }
}

export default new StripeApi();
