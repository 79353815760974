import _ from 'lodash';
import Login from './components/pages/Login';
import SignFile from './components/pages/SignFile';
import Account from './components/pages/Account';
import SignedFiles from './components/pages/SignedFiles';
import Welcome from './components/pages/Welcome';
import Users from './components/pages/Users';
import Blacklisted from './components/pages/Blacklisted';
import CheckHash from './components/pages/CheckHash';


interface ApplicationRouteGroup {
  name: string;
  routes: ApplicationRoute[];
}

interface ApplicationRoute {
  path: string;
  name: string;
  restricted: boolean;
  exact?: boolean;
  component: any;
  menuLabel?: string;
}

export const appRoutes: ApplicationRouteGroup[] = [
  {
    name: 'public',
    routes: [
      {
        path: '/login',
        name: 'login',
        restricted: false,
        exact: true,
        component: Login,
        menuLabel: 'Home'
      },
      {
        path: '/welcome',
        name: 'welcome',
        restricted: false,
        exact: true,
        component: Welcome,
        menuLabel: 'Home'
      }
    ]
  },
  {
    name: 'private',
    routes: [
      {
        path: '/',
        name: 'home',
        restricted: false,
        exact: true,
        component: SignFile,
        menuLabel: 'Home'
      },
      {
        path: '/sign-file',
        name: 'signFile',
        restricted: false,
        exact: true,
        component: SignFile,
        menuLabel: 'Sign a File'
      },
      {
        path: '/check-hash',
        name: 'checkHash',
        restricted: false,
        exact: true,
        component: CheckHash,
        menuLabel: 'check-hash'
      },
      {
        path: '/signed-files',
        name: 'listSignedFile',
        restricted: false,
        exact: true,
        component: SignedFiles,
        menuLabel: 'List Signed Files'
      },
      {
        path: '/add-certificate',
        name: 'addCertificate',
        restricted: false,
        exact: true,
        component: null,
        menuLabel: 'Add a certificate'
      },
      {
        path: '/account',
        name: 'account',
        restricted: false,
        exact: true,
        component: Account,
        menuLabel: 'Account'
      },
      {
        path: '/users',
        name: 'users',
        restricted: false,
        exact: true,
        component: Users,
        menuLabel: 'Users'
      }
    ]
  },
  {
    name: 'blacklisted',
    routes: [
      {
        path: '/blacklisted',
        name: '/blacklisted',
        restricted: false,
        exact: true,
        component: Blacklisted,
        menuLabel: 'Home'
      }
    ]
  }
];

export function getRoutesGroupByName(
  name: string,
  excludeRestricted: boolean = true
) {
  let filteredRoutes: any = _.find(appRoutes, { name });
  if(!filteredRoutes) return [];

  let result = filteredRoutes.routes;
  if (excludeRestricted) {
    result = _.filter(result, { restricted: !excludeRestricted });
  }
  
  return result;
}
