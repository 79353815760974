import { useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DigestTable from "../tables/digestTable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import { IFilter } from "../../interfaces/filter";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  function isEmpty(o: object) {  
    return Object.keys(o).length === 0
  }

 function Digest() {



  let emptyfilter: IFilter= {
    value: "",
    type: "hash",
    blacklisted: false,
    deprecated: false,
  }
  
    const [filter, setFilter] = useState(emptyfilter);
    const [trigger, setTrigger] = useState(moment());
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchTextTimeout, setSearchTextTimeout] = useState(0);
    

    return (
        <div className="page-panel">
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
                open={openBackDrop}
            >
                <CircularProgress color="inherit" />
                
            </Backdrop>


            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="top"
                style={{ height: "100%" }}
            >

              <Grid
                container
                spacing={0}
                direction="row"
                alignItems="center"
                justifyContent="center"
                
              >
                  <Grid item  xs={7} >
                    <TextField
                      className="search-field"
                      placeholder="Search"
                      variant="outlined"
                      fullWidth
                      value={filter.value}
                      onChange={(e) => {
                        
                        if(searchTextTimeout!=0){
                          clearTimeout(searchTextTimeout);
                        };
                        setFilter(prevFilter=>({
                          ...prevFilter,
                          value: e.target.value
                        }));

                        setSearchTextTimeout(
                          window.setTimeout(
                             function(){ setTrigger(moment());}
                             ,900),
                             );
                    }
                    }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                  </Grid>
                  <Grid item  xs={1} >

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter.type}
                        onChange={(e) => {
                            setFilter(prevFilter=>({
                                    ...prevFilter,
                                    type: e.target.value
                            }))
                            if(filter.value!="")         
                              setTrigger(moment());
                          }
                        }
                      >
                        <MenuItem value="digestId">ID</MenuItem>
                        <MenuItem value="hash">Hash</MenuItem>
                        <MenuItem value="userName">UserName</MenuItem>
                      </Select>
                  </Grid>
                  <Grid item   xs={2} >
                      <Checkbox name="balcklisted" 
                        onChange={(e) => {
                            setFilter(prevFilter=>({
                              ...prevFilter,
                              blacklisted: e.target.checked
                            }));
                           setTrigger(moment());
                           //console.log(filter.blacklisted);
                        }
                        }
                        /> <span>blacklisted</span>
                      <Checkbox name="deprecated"  onChange={(e) => {
                            setFilter(prevFilter=>({
                              ...prevFilter,
                              deprecated: e.target.checked
                          }));
                          setTrigger(moment());
                        }  
                        }  /> <span>deprecated</span>
                  </Grid>
                </Grid>
              
                <Grid item xs={3} style={{ padding: "2px", width: "100%" }}>
                  <DigestTable filter={filter} trigger={trigger} setUsersParent={setUsers} />
                      
                </Grid>
              
          </Grid>
        </div>
      );
}

export default Digest




