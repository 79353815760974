import AwsS3 from "@uppy/aws-s3";
import { Dashboard } from "@uppy/react";
import { Uppy } from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
// import axios from "axios";
import AuthUtils from "../../utils/authUtils";
import ArtifactsApi from "../../api/artifactsApi";
import { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, TextField, Typography } from "@mui/material";
import { Auth } from "aws-amplify";

const uppy = new Uppy({
  autoProceed: false,
});
// uppy.use(Tus, {
//   endpoint: "https://tusd.tusdemo.net/files/", // use your tus endpoint here
//   resume: true,
//   retryDelays: [0, 1000, 3000, 5000],
//   autoProceed: true
// });
uppy.use(AwsS3, {
  getUploadParameters: async (file: any) => {
    const userData: any = AuthUtils.getIdTokenPayload();
    console.log("Before upload:", file);
    const res = await ArtifactsApi.getSignedURL({
      extension: file.extension,
      type: file.type,
      original_name: file.name,
      username: userData["cognito:username"],
      tenantId: userData["custom:tenantId"],
    });
    // axios.get(
    //   "https://1zijscu1fh.execute-api.us-east-1.amazonaws.com/artifact/signed-url",
    //   { params: { extension: file.extension, type: file.type, original_name: file.name, username: userData['cognito:username'], tenantId: userData['custom:tenantId'] } }
    // );
    console.log("presignedURL", res.data.uploadURL);
    return {
      method: "PUT",
      url: res.data.uploadURL,
      // fields: data.fields,
      // Provide content type header required by S3
      headers: {
        "Content-Type": file.type,
      },
    };
  },
});

uppy.on("upload", (data: any) => {
  // data object consists of `id` with upload ID and `fileIDs` array
  // with file IDs in current upload
  const { id, fileIDs } = data;
  console.log(`Starting upload ${id} for files ${fileIDs}`);
});

uppy.on("progress", (progress: any) => {
  // progress: integer (total progress percentage)
  console.log(progress);
});

uppy.on("upload-success", (file: any, response: any) => {
  console.log("upload success");
  console.log(file.name, response.uploadURL);
});

uppy.on("complete", (result: any) => {
  console.log("successful files:", result.successful);
  console.log("failed files:", result.failed);
});

uppy.on("error", (error: any) => {
  console.error(error.stack);
});

uppy.on("upload-error", (file: any, error: any, response: any) => {
  console.log("error with file:", file.id);
  console.log("error message:", error);
});

uppy.on("file-removed", (file: any, response: any) => {
  console.log("cancelled all files");
});

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "15px",
  boxShadow: 24,
  p: 4,
};

function SignFile() {
  const [otpCode, setOtpCode] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [otpCodeError, setOtpCodeError] = useState("");
  const [mfaMethod, setMfaMethod] = useState("");

  useEffect(() => {
    uppy.on("file-added", (file: any) => {
      console.log("Added file", file);
      setOpenModal(true);
    });

    Auth.currentAuthenticatedUser().then((user) => {
      const mfaMethod = AuthUtils.getMfaMethodFromUser(user);
      setMfaMethod(mfaMethod);
      if(mfaMethod === "") {
        setOtpCodeError("It is required to have a MFA method configured to proceed with this operation");
      }
    });
  }, []);

  const handleClose = () => {
    setOpenModal(false);
    setOtpCode("");
    uppy.removeFile(uppy.getFiles()[0].id);
  };

  const handleCheckOtpCode = async () => {
    const user = await Auth.currentAuthenticatedUser();

    if (mfaMethod === "TOTP") {
      try {
        const isOtpValid = await Auth.verifyTotpToken(user, otpCode);
        if (isOtpValid) {
          setOpenModal(false);
          setOtpCode("");
          uppy.upload();
        } else {
          setOtpCodeError("Invalid OTP Code");
        }
      } catch (err) {
        setOtpCodeError("Invalid OTP Code");
      }
    }

    if (mfaMethod === "Yubikey") {
      const isOtpValid = await AuthUtils.checkYubikeyOTP(otpCode);
      try {
        if (isOtpValid) {
          setOpenModal(false);
          setOtpCode("");
          uppy.upload();
        } else {
          setOtpCodeError("Invalid OTP Code");
        }
      } catch (err: any) {
        setOtpCodeError(err.message);
      }
    }

    if (mfaMethod === "") {
      setOtpCodeError(
        "It is required to have a MFA method configured to proceed with this operation"
      );
    }
  };

  return (
    <div className="page-panel">
      {mfaMethod === "TOTP" || mfaMethod === "Yubikey" ? (
        <>
          <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-add-user-title"
            aria-describedby="modal-add-user-description"
          >
            <Box sx={modalStyle} style={{ textAlign: "center" }}>
              <Typography
                id="modal-add-user-title"
                variant="h6"
                component="h2"
                style={{ width: "100%", marginBottom: "10%" }}
              >
                {mfaMethod === "TOTP"
                  ? "Enter your OTP Code"
                  : mfaMethod === "Yubikey"
                  ? "Enter your Yubikey OTP Code"
                  : "It is required to have a MFA method configured to proceed with this operation"}
              </Typography>
              <div id="modal-add-user-description">
                <Grid
                  container
                  spacing={2}
                  direction="column"
                  alignItems="center"
                  justifyContent="top"
                  style={{ height: "100%" }}
                >
                  {mfaMethod === "TOTP" || mfaMethod === "Yubikey" ? (
                    <>
                      <Grid item xs={3} style={{ width: "80%" }}>
                        <TextField
                          value={otpCode}
                          onChange={(e) => setOtpCode(e.target.value)}
                          className="add-user-input"
                          placeholder="OTP Code"
                          variant="outlined"
                          fullWidth
                          error={otpCodeError.length > 0}
                          helperText={otpCodeError}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ width: "100%", marginTop: "10%" }}
                      >
                        <Button
                          style={{ marginLeft: "5%" }}
                          variant="contained"
                          color="error"
                          onClick={handleClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          style={{ marginLeft: "5%", marginRight: "10%" }}
                          variant="contained"
                          color="success"
                          onClick={handleCheckOtpCode}
                        >
                          Save
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={3} style={{ width: "80%" }}>
                      {otpCodeError.length > 0 && (
                        <Typography variant="body1" color="error">
                          {otpCodeError}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </div>
            </Box>
          </Modal>
          <Dashboard
            uppy={uppy}
            proudlyDisplayPoweredByUppy={false}
            showProgressDetails={true}
            hideUploadButton={true}
            width="100%"
            locale={{
              strings: {
                dropPasteFiles: "Drag files here to upload or %{browseFiles}",
                browseFiles: "browse from your computer",
              },
            }}
          />
        </>
      ) : (
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Grid item xs={3} style={{ width: "100%", textAlign: "center" }}>
            <Typography variant="body1" color="error">
              {otpCodeError}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default SignFile;
